<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            HelixPay Partners with Barefoot Theatre Collaborative for
            <span class="nowrap">Bar Boys’</span> Final Week Success
          </h1>
          <div class="post-date">November 19, 2024</div>
        </section>

        <div class="main">
          <div class="image-container square">
            <vLazyImage src="/img/articles/barboys-headliner.jpg" />
          </div>

          <p>
            <em>Manila, Philippines</em> — HelixPay is proud to have been part
            of the final week extension of Bar Boys, Barefoot Theatre
            Collaborative's celebrated adaptation of the popular Filipino film,
            held from November 7-10 at the PMCS Blackbox Theater, Circuit
            Makati. The production wrapped up a highly successful run, reaching
            a total of 30 shows since its opening on October 4.
          </p>

          <p>
            The extended shows drew enthusiastic audiences, eager to experience
            the dynamic story of friendship, ambition, and resilience brought to
            life on stage. HelixPay’s partnership with Barefoot Theatre
            Collaborative made it easier than ever for fans to secure tickets,
            showcasing HelixPay’s commitment to supporting the local arts
            community and creating seamless event experiences.
          </p>

          <div class="image-container square">
            <vLazyImage src="/img/articles/barboys-testimonial.jpg" />
          </div>

          <p>
            HelixPay’s role in this partnership underscores our dedication to
            empowering arts and culture through innovative event solutions,
            helping expand accessibility and engagement for Filipino theater
            audiences. By working alongside Barefoot Theatre Collaborative, we
            continue to demonstrate the power of digital solutions in elevating
            live theater experiences, bringing more people into the heart of
            Filipino creative storytelling.
          </p>

          <p>
            Let’s explore how we can also empower your events! Connect with us
            at <a href="tel:63288417125">63288417125</a> and
            <a href="mailto:hello@helixpay.ph">hello@helixpay.ph</a>.
          </p>
        </div>
      </div>
    </main>

    <MainCCFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import vLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/barboys-headliner.jpg';

const articlePath =
  '/articles/helix-pay-partners-with-barefoot-theatre-collaborative-for-bar-boys-final-week-success';

useHead(
  buildPageMeta({
    title: `HelixPay Partners with Barefoot Theatre Collaborative for Bar Boys’ Final Week Success`,
    description: `HelixPay is proud to have been part of the final week extension of Bar Boys, Barefoot Theatre Collaborative's celebrated adaptation of the popular Filipino film.`,
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/barefoot-bg.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #b78372;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main p:first-child {
  margin-top: 0 !important;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 4rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.15rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.square {
  padding-bottom: 100%;
}

.aspect-ratio-0-75 {
  padding-bottom: 133%;
}

.image-container {
  background: #c1ccd7;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main strong {
  font-weight: 600;
}

.main p,
.main h2 {
  margin: 2rem 1.5rem;
}

.main p {
  font-size: 0.9rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .main p,
  .main h2 {
    margin: 3rem auto;
    max-width: 512px;
  }

  .main p {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p,
  .main h2 {
    max-width: 610px;
  }

  .main p {
    font-size: 1.15rem;
  }
}

.nowrap {
  white-space: nowrap;
}

.post-date {
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.7;
}

strong {
  font-weight: 600;
}

a {
  color: #25a4e1;
}
</style>
